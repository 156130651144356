<template>
  <div>
    <div class="pititle">
      <el-form>
        <div class="litop">
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="项目名称">
                  <el-input
                    v-model="search_form.name"
                    placeholder="请输入您的项目名称"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="建设单位">
                  <el-input
                    v-model="search_form.build_company"
                    placeholder="请输入建设单位"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light"></div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple"></div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light"></div>
            </el-col>
            <el-col :span="4">
              <div class="grid-content bg-purple"></div>
            </el-col>
          </el-row>
        </div>
        <div class="litop">
          <el-row>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <el-form-item label="项目状态">
                  <el-select
                    v-model="status"
                    placeholder="项目状态"
                    clearable
                    @blur="saveSearchInSession"
                  >
                    <el-option label="未提交" :value="21"></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple-light">
                <el-form-item label="项目城市">
                  <el-input
                    v-model="search_form.city"
                    placeholder="请输入项目城市"
                    @blur="saveSearchInSession"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="5">
              <div class="grid-content bg-purple">
                <div class="prbtn">
                  <span>共{{ totalCount }}条</span>
                  <el-button type="primary" @click="search">查询</el-button>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple-light">
                <div class="btnbtn">
                  <div class="prbtn">
                    <el-button @click="check_del" type="warning"
                      >删除</el-button
                    >
                  </div>
                  <div class="prbtn" style="margin-right: 30px">
                    <el-button
                      type="primary"
                      @click="$router.push({ path: './prorecAdd' })"
                      >新增备案</el-button
                    >
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-form>
      <div class="piconetn">
        <!-- 表格 -->
        <div class="pitable">
          <el-table
            :data="tableData"
            stripe
            style="width: 100%"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="55" text-align="center">
            </el-table-column>
            <el-table-column label="报告编号" prop="number" align="center">
            </el-table-column>
            <el-table-column label="项目名称" align="center" min-width="180">
              <template slot-scope="{ row }">
                <router-link
                  :to="'./prorecAdd?project_id=' + row.id"
                  style="color: #3086fb; cursor: pointer; text-decoration: none"
                >
                  {{ row.name }}
                </router-link>
              </template>
            </el-table-column>
            <el-table-column
              label="建设单位"
              prop="build_company"
              align="center"
            >
            </el-table-column>
            <el-table-column label="项目城市" prop="city" align="center">
            </el-table-column>
            <el-table-column label="建筑类型" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.building_type == 1">公共建筑</span>
                <span v-if="row.building_type == 2">居住建筑</span>
              </template>
            </el-table-column>
            <el-table-column
              label="测评机构"
              prop="test_company"
              align="center"
            >
            </el-table-column>
            <el-table-column label="测评星级" align="center">
              <template slot-scope="{ row }">
                <i
                  v-for="item in row.stars"
                  :key="item"
                  class="el-icon-star-on"
                ></i>
              </template>
            </el-table-column>
            <el-table-column label="备案时间" prop="created_at" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.created_at | formatDate("yyyy-MM-dd") }}</span>
              </template>
            </el-table-column>
            <el-table-column label="项目状态" align="center">
              <template slot-scope="{ row }">
                <span v-if="row.status == 1">未提交</span>
                <span v-else-if="row.status == 2">形式初审</span>
                <span v-else-if="row.status == 3">形式复审</span>
                <span v-else-if="row.status == 4">审查分配</span>
                <span v-else-if="row.status == 5">专家初审</span>
                <span v-else-if="row.status == 6">专家复审</span>
                <span v-else-if="row.status == 7">专家反馈</span>
                <span v-else-if="row.status == 8">审核通过</span>
                <span v-else-if="row.status == 9">待公示</span>
                <span v-else-if="row.status == 10">公示中</span>
                <span v-else-if="row.status == 11">公告中</span>
                <span v-else-if="row.status == 12">待寄送</span>
                <span v-else-if="row.status == 13">待自取</span>
                <span v-else-if="row.status == 14">已完成</span>
                <span v-else-if="row.status == 15">待修改</span>
                <span v-else-if="row.status == 16">已修改</span>
                <span v-else-if="row.status == 21">未提交</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 分页 -->
        <div class="pageTotal">
          <div class="block">
            <el-pagination
              :current-page="page"
              :page-count="totalPage"
              :page-size="limit"
              :total="totalCount"
              background
              layout=" prev, pager, next, total, jumper"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { delProject, getProjectList } from "../../../../api/project";

export default {
  filters: {
    formatDate: function (timestamp, fmt) {
      if (fmt == undefined || fmt == "" || fmt == null) {
        fmt = "yyyy-MM-dd";
      }
      if (!timestamp) {
        return "";
      }
      let date = new Date(timestamp);
      // 第一步先替换年份 因为年份跟其他不一样是四位
      // let pat =
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, date.getFullYear());
      }
      // 第二步匹配其他，然后替换
      let obj = {
        "M+": date.getMonth() + 1,
        "d+": date.getDate(),
        "h+": date.getHours(),
        "m+": date.getMinutes(),
        "s+": date.getSeconds(),
      };
      for (let key in obj) {
        let pat = `(${key})`;
        if (new RegExp(pat).test(fmt)) {
          let str = obj[key] + "";
          // RegExp.$1  MM dd hh mm ss贪婪匹配
          fmt = fmt.replace(RegExp.$1, ("00" + str).substr(-2));
        }
      }
      return fmt;
    },
  },
  data() {
    return {
      search_form: {},
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      status: "",
    };
  },
  mounted() {
    this.getSearchInSession();
    this.getList();
  },
  methods: {
    saveSearchInSession() {
      if (this.search_form) {
        sessionStorage.setItem(
          "itemRecord_search",
          JSON.stringify(this.search_form)
        );
      }
    },
    getSearchInSession() {
      let searchForm = sessionStorage.getItem("itemRecord_search");
      if (searchForm && searchForm != "") {
        this.search_form = JSON.parse(searchForm);
      }
    },
    handleSelectionChange(val) {
      this.checked_list = val;
    },

    //删除数据
    check_del() {
      let that = this;
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let ids = this.checked_list.map((item) => {
            return item.id;
          });
          // console.log(ids);
          let params = {
            token: this.$store.state.token,
            id: ids,
          };
          delProject(params).then((res) => {
            console.log(res);
            that.getList();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //获得表格数据
    getList() {
      let that = this;
      let params = this.search_form;
      params.company = 1;
      params.page = this.page;
      params.limit = this.limit;
      params.token = this.$store.state.token;
      if (this.status && this.status != "") {
        params.status = this.status;
      } else {
        params.status = 21;
      }
      getProjectList(params)
        .then((res) => {
          // console.log(res);
          that.tableData = res.data;
          that.page = res.page;
          that.totalCount = res.totalCount;
          that.totalPage = res.totalPage;
           if (!that.tableData || that.tableData.length == 0) {
            this.$message.warning("暂无数据");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //搜索
    search() {
      this.page = 1;
      this.getList();
    },

    // 分页
    handleSizeChange(page) {
      this.limit = page;
      this.getList();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(page) {
      //console.log(`当前页: ${page}`)
      this.page = page;
      this.getList();
    },
  },
};
</script>
<style scoped>
/deep/ .el-table th.el-table__cell > .cell {
  min-width: 100px;
}
/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}
/deep/.el-form-item__label {
  font-size: 18px;
}
/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 255px;
}

/deep/ .el-table th.el-table__cell > .cell {
  color: #3086fb;
  font-weight: bold;
  font-size: 18px;
}
.pitable {
  height: 60vh;
}
.litop {
  margin: 20px 24px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 38px 0;
}
.prbtn {
  display: flex;
  flex-direction: row-reverse;
}
.btnbtn {
  display: flex;
  flex-direction: row-reverse;
}
</style>
